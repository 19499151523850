@import "../../../assets/scss/_variables";
.container-fluid {
  padding: 0px !important;
}
.page__wrapper {
  display: flex;
  position: relative;
  min-height: 100vmin;
  .main__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
  }
}
.pro-sidebar {
  .pro-sidebar-inner {
    background: $dark-gray-color !important;
    height: 100% !important;

    .collapsed {
      .closemenu {
        transform: rotate(180deg);
      }
    }
    .pro-sidebar-header {
      min-height: 68px;
      background: $dark-black-color;
      border-bottom: none !important;
      .logotext {
        img {
          height: 40px;
        }
      }
    }
    .pro-sidebar-content {
      .closemenu {
        position: absolute;
        right: -10px;
        top: 12px;
        z-index: 99999;
      }
    }
    .pro-menu {
      .pro-menu-item {
        &:nth-child(1) {
          padding: 0 !important;
        }
        height: 60px;
        &.active {
          background: $dashboard-active;
          border-left: 4px solid $white-color;
        }
        .pro-inner-item {
          min-height: 60px;
          padding: 0px;
          .pro-item-content {
            font-family: $poppins-regular;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $white-color;
          }
        }
      }
    }
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 100%;
  }
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
  overflow-y: visible !important;
  overflow-x: visible !important;
}

@import "../../../assets/scss/_variables";

.auth__screenContent {
  width: 100%;
  max-width: 384px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .brand-logo {
    img {
      height: 55px;
    }
  }
  .page__title {
    h4 {
      font-family: $workSans-bold;
      font-weight: 700;
      font-size: 48px;
      line-height: 56px;
      color: $auth-page-title;
    }
  }
  .form__section {
    max-width: 460px;
    .error {
      font-family: $workSans-regular;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: $error-validation;
    }
    .success {
      font-family: $workSans-regular;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: $success-validation;
    }
    .list__success {
      list-style: disc !important;
    }
    .must-item {
      list-style: circle;
      margin-bottom: 12px;
      ::marker {
        font-size: 20px;
      }
      .validation-label {
        font-family: $workSans-regular;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: $validation-label;
      }
      .success {
        font-family: $workSans-regular;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: $success-validation;
      }
    }
    .form-control {
      &:focus {
        box-shadow: none;
      }
    }
    .field-wrapper {
      position: relative;
      margin-bottom: 15px;
      .error-input {
        border: 1px solid $error-validation !important;
        &:focus {
          border: 1px solid $error-validation !important;
        }
      }
      input:not([disabled]) ~ .error-span {
        color: $error-validation !important;
      }
    }

    .field-wrapper input {
      border: 1px solid $form-input;
      border-radius: 8px !important;
      padding: 15px;
      border-radius: 4px;
      width: 100%;
      font-family: $workSans-regular;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $input-text;
      &::placeholder {
        font-family: $workSans-regular;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $form-input;
      }
    }

    .field-wrapper input:focus {
      border: 1px solid $blue-color !important;
      outline: none;
    }
    .field-wrapper input:focus-visible {
      border: 1px solid $blue-color !important;
      outline: none;
    }

    .field-wrapper .field-placeholder {
      font-size: 16px;
      position: absolute;
      /* background: #fff; */
      bottom: 14px;
      // -webkit-box-sizing: border-box;
      // box-sizing: border-box;
      left: 8px;
      padding: 0 8px;
      -webkit-transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
        opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
      transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
        opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
      z-index: 1;

      text-align: left;
      width: 100%;
    }

    .field-wrapper .field-placeholder span {
      background: $white-color;
      padding: 0px 8px;
    }

    .field-wrapper input:not([disabled]) ~ .field-placeholder {
      font-family: $workSans-medium;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: $placeholder-color;
    }
    .field-wrapper input:not([disabled]) ~ .field-placeholder,
    .field-wrapper.hasValue input:not([disabled]) ~ .field-placeholder {
      -webkit-transform: scale(0.75) translateY(-39px) translateX(-60px);
      transform: scale(0.75) translateY(-39px) translateX(-60px);
    }
    .eye__icon {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
  }
  .form-check-label {
    font-family: $workSans-regular;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $label-color;
    a {
      color: $primary-color;
      text-decoration: none;
    }
  }
  .form-check-input:checked {
    background-color: $primary-color;
    border-color: $primary-color;
    &:focus {
      box-shadow: none;
    }
  }
  .form-check-input {
    border-radius: 3px;
    &:focus {
      box-shadow: none;
    }
  }
  .forgot-text {
    font-family: $workSans-regular;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;
    color: $blue-color;
  }
  .button__section {
    button {
      background: $primary-color;
      border: none;
      outline: none;
      border-radius: 8px;
      font-family: $workSans-bold;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: $white-color;
      width: 100%;
      padding: 16px;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .already__text {
    font-family: $workSans-bold;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-decoration: none;
    text-align: center;
    color: $primary-color;
  }
}
@media (max-width: 1024px) {
  .auth__screenContent {
    padding: 50px 25px;
    height: 100vmax;
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 992px) {
  .auth__screenContent {
    .page__title {
      h4 {
        font-size: 32px;
      }
    }
  }
}
@media (max-width: 768px) {
  .auth__screenContent {
    padding: 50px 25px;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 576px) {
  .auth__screenContent {
    padding: 50px 25px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    .page__title {
      margin: 20px 0;
      h4 {
        font-size: 24px;
      }
    }
  }
  #sidebar-component {
    display: none !important;
  }
}
@media (max-width: 376px) {
  .auth__screenContent {
    padding: 50px 18px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    .page__title {
      margin: 20px 0;
      h4 {
        font-size: 24px;
      }
    }
  }
}
@media (max-width: 320px) {
  .auth__screenContent {
    padding: 50px 18px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    .page__title {
      margin: 20px 0;
      h4 {
        font-size: 24px;
      }
    }
  }
}
.popup__modalOverlay {
  opacity: 0.5;
  background: rgba(32, 32, 32, 0.5);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  position: relative;
  z-index: 9999;
}
.popup__modal {
  margin: 0 auto;
  width: 100%;
  max-width: 432px;
  z-index: 9999;
  .modal-content {
    border: none;
    border-radius: 8px;
  }
  .popup__content {
    padding: 32px 24px 32px 24px;
    .popup__title {
      display: flex;
      position: relative;
      justify-content: center;
      img {
        margin: 0 auto;
        position: absolute;
        right: 0;
        top: -10px;
        cursor: pointer;
      }
      h4 {
        font-family: $workSans-medium;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        color: $auth-page-title;
      }
    }
    .popup__description {
      width: 100%;
      max-width: 256px;
      margin: 0 auto;
      p {
        font-family: $workSans-regular;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $popup-description;
      }
      .button__section {
        max-width: 156px;
        margin: 0 auto;
        button {
          background: $primary-color;
          border: none;
          outline: none;
          border-radius: 8px;
          font-family: $workSans-bold;
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          color: $white-color;
          width: 100%;
          padding: 16px;
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}

@import "../../../assets/scss/_variables";
.signup__screenContent {
  max-width: 420px;
  width: 100%;
  .brand-logo {
    img {
      height: 55px;
    }
  }
  .forgotPage__title {
    h4 {
      font-family: $workSans-bold;
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      color: $auth-page-title;
    }
  }
  .page__description {
    max-width: 422px;
    width: 100% !important;
    p {
      font-family: $workSans-regular;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      color: $para-text;
    }
  }
  .button__section {
    button {
      background: $primary-color;
      border: none;
      outline: none;
      border-radius: 8px;
      font-family: $workSans-bold;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: $white-color;
      width: 100%;
      padding: 16px;
      &:focus {
        box-shadow: none;
      }
    }
  }
}


@import "../../../assets/scss/_variables";
.login__screenContent {
  width: 100%;
  max-width: 384px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // height: 100%;
  .brand-logo {
    img {
      height: 55px;
    }
  }
  .page__title {
    h4 {
      font-family: $workSans-bold;
      font-weight: 700;
      font-size: 48px;
      line-height: 56px;
      color: $auth-page-title;
    }
  }
  .forgotPage__title {
    h4 {
      font-family: $workSans-bold;
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      color: $auth-page-title;
    }
  }
  .page__description {
    p {
      font-family: $workSans-regular;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      color: $para-text;
    }
  }
  .form__section {
    max-width: 460px;
    .error {
      font-family: $workSans-regular;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: $error-validation;
    }
    .success {
      font-family: $workSans-regular;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: $success-validation;
    }
    .list__success {
      list-style: solid;
    }
    .must-item {
      list-style: circle;
      ::marker {
        font-size: 20px;
      }
      .validation-label {
        font-family: $workSans-regular;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: $validation-label;
      }
      .success {
        font-family: $workSans-regular;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: $success-validation;
      }
    }
    .form-control {
      &:focus {
        box-shadow: none;
      }
    }
    .field-wrapper {
      position: relative;
      margin-bottom: 15px;
      .error-input {
        border: 1px solid $error-validation !important;
        &:focus {
          border: 1px solid $error-validation !important;
        }
      }
      input:not([disabled]) ~ .error-span {
        color: $error-validation !important;
      }
    }

    .field-wrapper input {
      border: 1px solid $form-input;
      border-radius: 8px !important;
      padding: 15px;
      border-radius: 4px;
      width: 100%;
      font-family: $workSans-regular;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $input-text;
      &::placeholder {
        font-family: $workSans-regular;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $form-input;
      }
    }

    .field-wrapper input:focus {
      border: 1px solid $blue-color !important;
      outline: none;
    }
    .field-wrapper input:focus-visible {
      border: 1px solid $blue-color !important;
      outline: none;
    }

    .field-wrapper .field-placeholder {
      font-size: 16px;
      position: absolute;
      /* background: #fff; */
      bottom: 14px;
      // -webkit-box-sizing: border-box;
      // box-sizing: border-box;
      left: 8px;
      padding: 0 8px;
      -webkit-transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
        opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
      transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
        opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
      z-index: 1;

      text-align: left;
      width: 100%;
    }

    .field-wrapper .field-placeholder span {
      background: $white-color;
      padding: 0px 8px;
    }

    .field-wrapper input:not([disabled]) ~ .field-placeholder {
      font-family: $workSans-medium;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: $placeholder-color;
    }
    .field-wrapper input:not([disabled]) ~ .field-placeholder,
    .field-wrapper.hasValue input:not([disabled]) ~ .field-placeholder {
      -webkit-transform: scale(0.75) translateY(-39px) translateX(-60px);
      transform: scale(0.75) translateY(-39px) translateX(-60px);
    }
    .eye__icon {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
  }
  .form-check-label {
    font-family: $workSans-regular;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $label-color;
    a {
      color: $primary-color;
      text-decoration: none;
    }
  }
  .form-check-input:checked {
    background-color: $primary-color;
    border-color: $primary-color;
    &:focus {
      box-shadow: none;
    }
  }
  .form-check-input {
    border-radius: 3px;
    &:focus {
      box-shadow: none;
    }
  }
  .forgot-text {
    font-family: $workSans-regular;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;
    color: $blue-color;
  }
  .button__section {
    button {
      background: $primary-color;
      border: none;
      outline: none;
      border-radius: 8px;
      font-family: $workSans-bold;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: $white-color;
      width: 100%;
      padding: 16px;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .already__text {
    font-family: $workSans-bold;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-decoration: none;
    text-align: center;
    color: $primary-color;
  }
}
@media (max-width: 992px) {
  .auth__screenContent {
    height: 100vh;
    .page__title {
      h4 {
        font-size: 32px;
      }
    }
  }
}
@media (max-width: 768px) {
  .auth__screenContent {
    height: 100%;
    padding: 50px 25px;
  }
}
@media (max-width: 576px) {
  .auth__screenContent {
    height: 100%;
    padding: 50px 25px;
    .page__title {
      margin: 10px 0;
      h4 {
        font-size: 24px;
      }
    }
  }
  #sidebar-component {
    display: none !important;
  }
}

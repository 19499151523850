/* Poppins Fonts */
@font-face {
  font-family: "poppinsblack";
  src: url("poppins-black-webfont.woff2") format("woff2"),
    url("poppins-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsblack_italic";
  src: url("poppins-blackitalic-webfont.woff2") format("woff2"),
    url("poppins-blackitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsbold";
  src: url("poppins-bold-webfont.woff2") format("woff2"),
    url("poppins-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsbold_italic";
  src: url("poppins-bolditalic-webfont.woff2") format("woff2"),
    url("poppins-bolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsextrabold";
  src: url("poppins-extrabold-webfont.woff2") format("woff2"),
    url("poppins-extrabold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsextrabold_italic";
  src: url("poppins-extrabolditalic-webfont.woff2") format("woff2"),
    url("poppins-extrabolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsextralight";
  src: url("poppins-extralight-webfont.woff2") format("woff2"),
    url("poppins-extralight-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsextralight_italic";
  src: url("poppins-extralightitalic-webfont.woff2") format("woff2"),
    url("poppins-extralightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinslight";
  src: url("poppins-light-webfont.woff2") format("woff2"),
    url("poppins-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinslight_italic";
  src: url("poppins-lightitalic-webfont.woff2") format("woff2"),
    url("poppins-lightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsmedium";
  src: url("poppins-medium-webfont.woff2") format("woff2"),
    url("poppins-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsregular";
  src: url("poppins-regular-webfont.woff2") format("woff2"),
    url("poppins-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinssemibold";
  src: url("poppins-semibold-webfont.woff2") format("woff2"),
    url("poppins-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinssemibold_italic";
  src: url("poppins-semibolditalic-webfont.woff2") format("woff2"),
    url("poppins-semibolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "poppinsthin";
  src: url("poppins-thin-webfont.woff2") format("woff2"),
    url("poppins-thin-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsthin_italic";
  src: url("poppins-thinitalic-webfont.woff2") format("woff2"),
    url("poppins-thinitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsmedium_italic";
  src: url("poppins-mediumitalic-webfont.woff2") format("woff2"),
    url("poppins-mediumitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

// work sans font family
@font-face {
  font-family: "work_sansblack";
  src: url("worksans-black-webfont.woff2") format("woff2"),
    url("worksans-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "work_sansbold";
  src: url("worksans-bold-webfont.woff2") format("woff2"),
    url("worksans-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "work_sansextrabold";
  src: url("worksans-extrabold-webfont.woff2") format("woff2"),
    url("worksans-extrabold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "work_sansextrabold_italic";
  src: url("worksans-extrabolditalic-webfont.woff2") format("woff2"),
    url("worksans-extrabolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "work_sanshairline";
  src: url("worksans-hairline-webfont.woff2") format("woff2"),
    url("worksans-hairline-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "work_sanslight";
  src: url("worksans-light-webfont.woff2") format("woff2"),
    url("worksans-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "work_sansmedium";
  src: url("worksans-medium-webfont.woff2") format("woff2"),
    url("worksans-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "work_sansregular";
  src: url("worksans-regular-webfont.woff2") format("woff2"),
    url("worksans-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "work_sanssemibold";
  src: url("worksans-semibold-webfont.woff2") format("woff2"),
    url("worksans-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "work_sansthin";
  src: url("worksans-thin-webfont.woff2") format("woff2"),
    url("worksans-thin-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@import "../../../assets/scss/_variables";
.navbar {
  background: $dark-black-color;
  height: 68px;
  .user__name {
    font-family: $poppins-medium;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: $white-color !important;
    pointer-events: none;
    span {
      color: $blue-color !important;
    }
    &:hover {
      text-decoration: none;
    }
  }
  .navbar-nav {
    a {
      &:last-child {
        border-left: 1px solid $separator-color;
        height: 32px;
      }
    }

    .nav-link {
      padding: 0px 15px;
      font-family: $poppins-regular;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: $white-color;

      .profile__img {
        height: 32px;
        width: 32px;
      }
    }
  }
}

// Fonts
@import "../fonts/stylesheet.scss";
// poppins font family
$poppins-bold: "poppinsbold";
$poppins-regular: "poppinsregular";
$poppins-medium: "poppinsmedium";
$poppins-semibold: "poppinssemibold";
$poppins-extrabold: "poppinsextrabold";
// work sans font family
$workSans-bold: "work_sansbold";
$workSans-regular: "work_sansregular";
$workSans-medium: "work_sansmedium";
$workSans-semibold: "work_sanssemibold";
$workSans-extrabold: "work_sansextrabold";

// Theme Color
$dashboard-active: #4c5053;
$dark-black-color: #161a1e;
$light-gray-color: #34383b;
$dark-gray-color: #34383b;
$light-green-color: #27ae60;
$warning-color: #d99207;
$yellow-color: #dbae0e;
$red-color: #eb5757;
$gray-color: #73797e;
$white-color: #ffffff;
$blue-color: #5b98f1;
$transparent-color: transparent;
$separator-color: #b7b7b7;
$page-title: #41464a;
$input-feild: #c7c5c5;
$filter-bg: #eeeeee;
$table-heading: #9aa2ad;
$table-headBg: #f8f8f8;
$apply-filter-bg: #f2f6f7;
$placeholder-color: #7e8d96;
$form-input: #b7b7b7;
$input-text: #2e3949;
$primary-color: #6a9eea;
$label-color: #616f83;
$para-text: #616f83;
$auth-page-title: #2e3949;
$popup-description: #616f83;
$table-hover: #f2f6f6;
$search-placeholder-color: #9aa2ad;

$success-validation: #0fd127;
$error-validation: #ef381f;
$validation-label: #9e9e9e;
// status color code
$processed-text-color: #27ae60;
$processed-color: #d5f3e2;
$initiated-text-color: #d99207;
$initiated-color: #ffe2aa;
$processing-text-color: #dbae0e;
$processing-color: #fef8be;
$failed-text-color: #eb5757;
$failed-color: rgba(235, 87, 87, 0.15);
$aborted-text-color: #73797e;
$aborted-color: #dadada;

@import "../../../assets/scss/_variables";
.forgot__screen {
  max-width: 408px;
}
@media (max-width: 992px) {
  .login__screenContent {
    .forgotPage__title {
      margin: 20px 0;
    }
    .page__description {
      p {
        font-size: 14px;
        line-height: unset;
      }
    }
  }
}

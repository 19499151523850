@import "../../assets/scss/_variables";
.login__screenWrapper {
  height: 100%;
  width: 100%;
  min-height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  .side__image {
    width: 100%;
    height: 100%;
  }
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(15.19deg, #042459 -9.61%, #bbc9e1 107.52%);
    opacity: 0.8;
    height: 100%;
    width: 100%;
  }
  .bg-image {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  .inner__content {
    position: absolute;
    height: 100vmin;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
    .logo-icon {
      opacity: 0.5;
      .right-icon {
        height: 203px;
      }
    }
    .content-brand {
      h4 {
        font-family: $workSans-bold;
        font-weight: 700;
        font-size: 48px;
        line-height: 56px;
        text-align: center;
        color: $white-color;
      }
      .description {
        width: 100%;
        max-width: 569px;
        margin: 0 auto;
        p {
          font-family: $workSans-regular;
          font-weight: 400;
          font-size: 18px;
          line-height: 27px;
          color: $white-color;
        }
      }
    }
  }
}
.modal {
  padding-left: 0px !important;
  .auth-screensModals {
    max-width: 100%;
    margin: 0 auto;
    height: 100%;
    .modal-content {
      border: none;
      outline: none;
      box-shadow: none;
      border-radius: unset;
      max-width: 100vw;
    }
  }
}
@media (max-width: 992px) {
  .login__screenWrapper {
    height: 100%;
    .inner__content {
      .logo-icon {
        .right-icon {
          height: 103px;
        }
      }
      .content-brand {
        h4 {
          font-size: 34px;
        }
        .description {
          p {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .login__screenWrapper {
    height: 100%;
    .inner__content {
      .logo-icon {
        .right-icon {
          height: 103px;
        }
      }
      .content-brand {
        h4 {
          font-size: 34px;
        }
        .description {
          p {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }
}
